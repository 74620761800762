.page[data-v-14ded243] {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.page .btn-container[data-v-14ded243] {
  text-align: right;
  margin-bottom: 8px;
}
.page .role[data-v-14ded243] {
  cursor: pointer;
  margin-right: 4px;
}
.page .role[data-v-14ded243]:hover {
  text-decoration: underline;
}
