.doctype[data-v-8b179aaa] {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.doctype .btn-container[data-v-8b179aaa] {
  text-align: right;
  margin-bottom: 8px;
}
.doctype .role[data-v-8b179aaa] {
  cursor: pointer;
  margin-right: 4px;
}
.doctype .role[data-v-8b179aaa]:hover {
  text-decoration: underline;
}
