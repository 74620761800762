.report[data-v-5d8fc68e] {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.report .btn-container[data-v-5d8fc68e] {
  text-align: right;
  margin-bottom: 8px;
}
.report .role[data-v-5d8fc68e] {
  cursor: pointer;
  margin-right: 4px;
}
.report .role[data-v-5d8fc68e]:hover {
  text-decoration: underline;
}
